.slick-slide {
  outline: none;
}

.slick-prev {
  left: 0;

  &:before {
    left: 52%;
    top: 50%;
    transform: translate(-33%, -50%) rotate(45deg);
  }
}

.slick-next {
  right: 0;

  &:before {
    top: 50%;
    left: 48%;
    transform: translate(-74%, -50%) rotate(-135deg);
  }
}

.slick-prev,
.slick-next {
  top: 50%;
  height: 82px;
  width: 82px;
  background-color: @green;
  border-radius: 0;

  &:hover,
  &:focus {
    &:before {
      opacity: 0.8;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  opacity: 1;
}
