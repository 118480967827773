.text-page {
    &__buttons-block {
        background-color: @gray-lighter;
    }

    &__breadcrumbs-block {
        background-color: @gray-lighter;
        border-bottom: 1px solid #E1E1E1;
    }

    &__breadcrumbs-list {
        display: flex;
        align-items: center;
        padding-top: 27px;
        padding-bottom: 18px;
        padding-left: 35px;
    }

    &__breadcrumbs-item {
        font-size: @fz-7;
        color: @gray;

        &+& {
            position: relative;
            margin-left: 29px;

            &:before {
                position: absolute;
                content: '>';
                left: -18px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__link {}
}