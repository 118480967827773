.definitions-block {
    &__wrap {
        margin-top: 67px;
    }

    &__title {}

    &__list {
        margin-top: 39px;
        padding-bottom: 28px;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        background-color: @gray-lighter;
        padding: 26px 25px 26px 24px;

        &+& {
            margin-top: 19px;
        }
    }

    &__item-img-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-width: 162px;
    }

    &__item-img {
        object-position: top;
    }

    &__item-desc {
        font-size: @fz-3;
        margin-left: 28px;
        line-height: 1.2;
        letter-spacing: -0.2px;
    }

    &__bottom {
        padding-top: 52px;
        border-top: 1px solid @gray-lighter;
        padding-bottom: 46px;
    }

    &__subtitle {
        text-align: center;
    }

    &__description-block {
        display: flex;
        align-items: flex-start;
        margin-top: 58px;
    }

    &__description-col-1 {
        width: 48.246%;
        padding-bottom: 36.492%;
    }

    &__description-img {}

    &__description-col-2 {
        width: 48.86%;
        margin-left: 2.89%;
    }

    &__description-list {}

    &__description-item {
        position: relative;
        font-size: @fz-3;
        font-weight: @fw-medium;
        padding-left: 38px;
        letter-spacing: -0.2px;
        line-height: 1.2;

        &+& {
            margin-top: 0.6px;
        }

        &:before {
            position: absolute;
            left: 0;
            counter-increment: desc-counter;
            content: counter(desc-counter) ". ";
            font-size: @fz-3;
            font-weight: @fw-s-bold;
        }

        &--b {
            &:before {
                font-weight: @fw-bold;
            }
        }
    }

    &__item-desc {
        position: relative;
        overflow: hidden;
        transition: .3s;
        padding-bottom: 25px;

        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 25px;
            background-color: @gray-lighter;
        }

        span {
            display: block;
            position: relative;
            padding-left: 25px;

            &:before {
                position: absolute;
                content: '';
                width: 4px;
                height: 4px;
                background-color: #000;
                border-radius: 50%;
                top: 10px;
                left: 0;
            }
        }
    }

    &__item-btn {
        display: none;
        z-index: 2;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) rotate(90deg);
        width: 20px;
        height: 20px;
        background-image: url(../img/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 22px 20px;
        transition: .3s;

        &.is-active {
            transform: translateX(-50%) rotate(-90deg);
        }

        &.visible {
            display: block;
        }
    }
}

@media all and(max-width: @1110) {
    .definitions-block {
        &__wrap {
            margin-top: 40px;
        }

        &__list {
            margin-top: 30px;
        }

        &__bottom {
            padding-top: 28px;
            padding-bottom: 40px;
        }

        &__description-block {
            margin-top: 30px;
        }
    }
}

@media all and(max-width: @1023) {
    .definitions-block {
        &__description-block {
            flex-direction: column;
        }

        &__description-col-1 {
            width: 100%;
            margin: 0 auto;
            max-width: 600px;
            padding-bottom: 53.492%;
        }

        &__description-img {
            object-fit: contain;
        }

        &__description-col-2 {
            width: 100%;
            max-width: 768px;
            margin-left: 0;
            margin: 30px auto 0;
        }
    }
}

@media all and(max-width: @850) {
    .definitions-block {
        &__item {
            padding: 20px;
        }

        &__item-img-wrap {
            min-width: initial;
            max-width: 130px;
            width: 20%;
        }

        &__item-desc {
            font-size: @fz-4;
            margin-left: 20px;
            width: 79%;
        }
    }
}

@media all and(max-width: @550) {
    .definitions-block {
        &__wrap {
            margin-top: 30px;
        }

        &__bottom {
            padding-bottom: 30px;
        }

        &__item {
            flex-direction: column;
        }

        &__item-img-wrap {
            min-width: initial;
            max-width: 60%;
            width: 100%;
            margin: 0 auto;
        }

        &__item-desc {
            margin-left: 0;
            width: 100%;
            margin-top: 20px;
            text-align: justify;
            line-height: 1.5;

            br+br {
                display: none;
            }
        }

        &__description-col-1 {
            padding-bottom: 80%;
        }

        &__description-item {
            font-size: @fz-4;
            padding-left: 32px;
            text-align: justify;
            line-height: 1.4;

            &:before {
                font-size: @fz-4;
            }
        }

        &__item-desc {
            span {
                +br {
                    display: none;
                }
            }
        }
    }
}