.page {
    position: relative; // Для того что бы абсолютно позиционированные элементы не ужимались на маленьких экранах
    min-width: 320px;
    font-family: @PFB;
    font-weight: @fw-norm;
    font-size: @fz-4;
    line-height: 1.3;
    counter-reset: desc-counter;
}

p,
span,
a {
    font-weight: @fw-norm;
}

b {
    font-weight: @fw-bold;
}

.blackout {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    transition: .3s;
    opacity: 0;

    &.is-active {
        opacity: 1;
        z-index: 9;
    }
}

.blue-text {
    color: #009FE3;
}

.container {
    max-width: 1200px;
    padding: 0 30px;
    margin: 0 auto;
}

@media all and(max-width: @1023) {
    .container {
        padding: 0 20px;
    }
}