@charset "UTF-8";

// Default Variables

@slick-font-path: "./fonts/";
@slick-font-family: "slick";
@slick-loader-path: "./";
@slick-arrow-color: white;
@slick-dot-color: #fff;
@slick-dot-color-active: @green;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-dot-character: "•";
@slick-dot-size: 6px;
@slick-opacity-default: 0.75;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 0.25;

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 82px;
    width: 82px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    transition: .2s;

    &:hover,
    &:focus {
        outline: none;
        color: transparent;

        &:before {
            opacity: @slick-opacity-on-hover;
        }
    }

    &.slick-disabled {
        opacity: 0;
        z-index: -1;
    }
}

.slick-prev:before,
.slick-next:before {
    font-family: @slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: @slick-arrow-color;
    opacity: @slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    & when (@slick-font-family ='slick') {

        /* Icons */
        @font-face {
            font-family: 'slick';
            font-weight: normal;
            font-style: normal;
            /* src: url('@{slick-font-path}slick.eot');
            src: url('@{slick-font-path}slick.eot?#iefix') format('embedded-opentype'),
            url('@{slick-font-path}slick.woff') format('woff'),
            url('@{slick-font-path}slick.ttf') format('truetype'),
            url('@{slick-font-path}slick.svg#slick') format('svg'); */
        }
    }
}

.slick-prev {
    left: 40px;
    z-index: 1;

    [dir="rtl"] & {
        left: auto;
        right: 40px;
    }

    &:before {
        position: absolute;
        top: 29px;
        left: 14px;
        content: '';
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        opacity: 1;
        transform: rotate(45deg);
        width: 34px;
        height: 34px;

        [dir="rtl"] & {
            content: @slick-next-character;
        }
    }
}

.slick-next {
    right: 40px;

    [dir="rtl"] & {
        left: 40px;
        right: auto;
    }

    &:before {
        position: absolute;
        top: 29px;
        right: 15px;
        content: '';
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        opacity: 1;
        transform: rotate(-135deg);
        width: 34px;
        height: 34px;

        [dir="rtl"] & {
            content: @slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -46px;
    width: 100%;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;

    li+li {
        margin-left: 12px;
    }

    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    color: @yellow-hov;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: @slick-dot-character;
                width: 20px;
                height: 20px;
                font-family: @slick-font-family;
                font-size: 31px;
                line-height: 20px;
                text-align: center;
                color: @gray;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transition: color .3s;
            }
        }

        &.slick-active button:before {
            color: #D9D9D9;
        }
    }

    &--yellow {
        bottom: 34px;

        li {
            button {
                &:before {
                    color: @yellow;
                }
            }

            &.slick-active button:before {
                color: #fff;
            }
        }
    }
}

@media all and (max-width: 1300px) {
    .slick-dots {
        padding-left: 80px;
    }
}

@media all and (max-width: 850px) {
    .slick-dots {
        li {
            button {
                &:before {
                    font-size: 28px;
                }
            }
        }

        li+li {
            margin-left: 0;
        }
    }
}

@media all and (max-width: @767) {
    .slick-dots {
        width: 100%;
        padding-right: 14px;
        padding-left: 14px;
        right: 0;
        left: initial;
        flex-wrap: nowrap;
        overflow: hidden;
        bottom: -45px;

        &--yellow{
            bottom: 15px;
        }
    }
}

@media all and (max-width: 550px) {

    .slick-prev,
    .slick-next {
        display: none;
    }
}