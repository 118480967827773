.clients-block {
    background-color: @gray-lighter;
    border-top: 1px solid #EAEAEA;

    &--text {
        background-color: #fff;
        border: none;

        .clients-block__wrap {
            padding-top: 65px;
            padding-bottom: 107px;
        }
    }

    &__wrap {
        padding-top: 55px;
        padding-bottom: 85px;
    }

    &__title {}

    &__slider {
        margin-top: 49px;

        .slick-track {
            display: flex;
        }
    }

    &__slide {
        cursor: pointer;

        &+& {
            margin-left: 28px;
        }
    }

    &__slide-row {
        display: flex;
        flex-direction: column;

        &+& {
            margin-top: 37px;
        }
    }

    &__slide-imgs-wrap {
        display: flex;
        align-items: flex-start;
    }

    &__slide-img-wrap {
        width: 49%;
        padding-bottom: 35.1%;

        &+& {
            margin-left: 2.24%;
        }
    }

    &__slide-img {
        border-radius: 2px;
    }

    &__slide-title {
        margin-top: 14px;
    }
}

@media all and(max-width: @1110) {
    .clients-block {
        &--text {
            .clients-block__wrap {
                padding-top: 40px;
                padding-bottom: 60px;
            }
        }

        &__wrap {
            padding-top: 40px;
            padding-bottom: 60px;
        }

        &__slider {
            margin-top: 30px;
        }

        &__slide-row {
            &+& {
                margin-top: 30px;
            }
        }

        &__slide-title {
            margin-top: 14px;
        }
    }
}

@media all and(max-width: @850) {
    .clients-block {
        &__slide-title {
            margin-top: 10px;
            font-size: @fz-4;
        }

        &__slider {
            .slick-list {
                margin: 0 -15px;
            }
        }

        &__slide {
            cursor: pointer;
            margin: 0 15px;

            &+& {
                margin-left: 15px;
            }
        }
    }
}

@media all and(max-width: @550) {
    .clients-block {
        &--text {
            .clients-block__wrap {
                padding-top: 30px;
            }
        }

        &__wrap {
            padding-top: 30px;
        }

        &__slide-row {
            &+& {
                margin-top: 20px;
            }
        }

        &__slide-img{
            &--video{
                object-fit: initial;
            }
        }
    }
}