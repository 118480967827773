.cost-block {
    background-color: @gray-blue;

    &__wrap {
        padding-top: 60px;
        padding-bottom: 57px;
    }

    &__title {}

    &__heading {
        display: flex;
        align-items: center;
        margin-top: 36px;
        padding: 0 66px 0 158px;
    }

    &__heading-title {
        color: #fff;
        font-weight: @fw-s-bold;
        font-size: @fz-4;

        &:first-child {
            margin-right: auto;
        }

        &+& {
            margin-left: 65px;
        }
    }

    &__services-list {
        margin-top: 16px;
    }

    &__service-item {
        display: flex;
        justify-content: space-between;
        min-height: 169px;
        padding: 34px 60px 34px 36px;
        background-color: #fff;

        &+& {
            margin-top: 20px;
        }
    }

    &__service-col-1 {
        display: flex;
        align-items: center;
    }

    &__service-img-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 101px;
        width: 100%;
        height: 100%;
        max-height: 103px;
    }

    &__service-img {
        object-fit: contain;
        height: 100%;
    }

    &__service-text {
        margin-left: 23px;
    }

    &__service-title {
        font-weight: @fw-s-bold;
        font-size: @fz-3;
    }

    &__service-characters {
        margin-top: 15px;
    }

    &__service-character {
        position: relative;
        font-weight: @fw-s-bold;
        font-size: @fz-4;
        padding-left: 10px;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 11px;
            width: 5px;
            height: 2px;
            background-color: #000;
        }
    }

    &__service-col-2 {
        display: flex;
        justify-content: space-between;
        max-width: 315px;
        width: 100%;
        margin-left: 30px;
        margin-top: 5px;
    }

    &__service-count {
        font-weight: @fw-s-bold;
        font-size: @fz-3;

        span {
            font-size: @fz-4;
            font-weight: @fw-s-bold;
        }
    }

    &__service-cost {
        font-weight: @fw-s-bold;
        font-size: @fz-3;

        span {
            font-size: @fz-4;
            font-weight: @fw-s-bold;
        }
    }
}

@media all and(max-width: @1110) {
    .cost-block {
        &__heading {
            padding: 0 15px 0 150px;
        }

        &__heading-title {
            font-size: @fz-5;
        }

        &__service-item {
            padding: 25px;
        }

        &__service-title {
            font-size: @fz-4;
        }

        &__service-character {
            font-size: @fz-5;

            &:before {
                top: 10px;
                width: 4px;
            }
        }

        &__service-col-2 {
            max-width: 270px;
        }

        &__service-count {
            font-size: @fz-4;

            span {
                font-size: @fz-5;
            }
        }

        &__service-cost {
            font-size: @fz-4;

            span {
                font-size: @fz-5;
            }
        }
    }
}

@media all and(max-width: @1023) {
    .cost-block {
        &__wrap {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__heading {
            margin-top: 30px;
            padding: 0 15px 0 130px;
        }

        &__heading-title {
            &+& {
                margin-left: 40px;
            }
        }

        &__service-item {
            padding: 20px;
        }

        &__service-img-wrap {}

        &__service-col-2 {
            max-width: 200px;
        }

        &__service-text {
            margin-left: 15px;
            margin-top: 0;
        }

        &__service-characters {
            margin-top: 5px;
        }
    }
}

@media all and(max-width: @767) {
    .cost-block {
        &__heading {
            padding: 0 20px;
        }

        &__heading-title {
            font-size: @fz-7;
            max-width: 100px;
            text-align: center;

            &+& {
                margin-left: 20px;
            }
        }

        &__service-col-1 {
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        &__service-text {
            margin-left: 0;
        }

        &__service-img-wrap {
            margin-top: 20px;
            justify-content: flex-start;
        }

        &__service-title {
            font-size: @fz-6;
        }

        &__service-character {
            font-size: @fz-7;

            &:before {
                top: 8px;
                width: 4px;
                height: 1px;
            }
        }

        &__service-col-2 {
            max-width: 180px;
            margin-left: 10px;
        }

        &__service-count {
            font-size: @fz-6;

            span {
                font-size: @fz-7;
            }
        }

        &__service-cost {
            font-size: @fz-6;

            span {
                font-size: @fz-7;
            }
        }
    }
}

@media all and(max-width: @550) {
    .cost-block {
        &__wrap {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        &__heading-title {
            text-align: center;
        }

        &__service-col-1 {
            flex-direction: column;
        }

        &__service-img-wrap {
            margin-top: 0;
            max-width: 140px;
            margin-bottom: 20px;
        }

        &__service-title {
            margin-bottom: 15px;
        }

        &__service-character {
            font-weight: @fw-norm;
            line-height: 1.5;

            &:before {
                top: 10px;
            }
        }

        &__service-col-2 {
            position: absolute;
            right: 40px;
            max-width: 127px;
        }
    }
}