@font-face {
  font-family: 'PFBeauSansPro';
  src: local('PFBeauSansPro-Thin'),
    url('../fonts/PFBeauSansPro-Thin.woff') format('woff');
  font-weight: @fw-thin;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFBeauSansPro';
  src: local('PFBeauSansPro-Light'),
    url('../fonts/PFBeauSansPro-Light.woff') format('woff');
  font-weight: @fw-norm;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFBeauSansPro';
  src: local('PFBeauSansPro-Regular'),
    url('../fonts/PFBeauSansPro-Regular.woff') format('woff');
  font-weight: @fw-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFBeauSansPro';
  src: local('PFBeauSansPro-SemiBold'),
    url('../fonts/PFBeauSansPro-SemiBold.woff') format('woff');
  font-weight: @fw-s-bold;
  font-style: normal;
  font-display: swap;
}