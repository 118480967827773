.map-block {
    border-bottom: 1px solid #E1E1E1;

    &__wrap {
        padding-top: 50px;
        padding-bottom: 59px;
    }

    &__title {}

    &__desc {
        text-align: center;
        margin-top: 48px;
        font-weight: @fw-s-bold;
    }

    &__selector-wrap {
        position: relative;
        margin-top: 36px;
        background-image: url(../img/map.png);
        background-repeat: no-repeat;
        background-size: calc(100% + 74px) 100%;
        background-position: 53% 3px;
    }

    &__selector-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 515px;
        width: 100%;
        margin: 0 auto;
    }

    &__selector-item {
        &.is-active {
            .map-block__selector-title {
                color: #000;
                border-color: #000;
            }
        }

        &+& {
            margin-left: 20px;
        }
    }

    &__selector-title {
        color: @light-text;
        padding-bottom: 14px;
        border-bottom: 2px solid transparent;
        transition: .3s;
        cursor: pointer;
        text-transform: uppercase;
        width: 100%;

        &:hover,
        &:focus {
            color: #000;
        }
    }

    &__selector-item {}

    &__selector-title {}

    &__countries-block {
        display: none;
        margin-top: 54px;

        &.is-active {
            display: block;
        }
    }

    &__countries-list {
        display: grid;
        grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 12%;
        justify-content: space-between;
        grid-gap: 0 0.8%;
    }

    &__country-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:nth-child(n+11) {
            margin-top: 3.3%;
        }
    }

    &__country-desc {
        font-size: @fz-7;
        margin-top: 4px;
        white-space: nowrap;
    }

    &__btn {
        max-width: 363px;
        margin: 30px auto 0;
    }
}

@media all and(max-width: @1110) {
    .map-block {
        &__wrap {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__desc {
            margin-top: 30px;
        }

        &__selector-wrap {
            background-size: calc(100% + 74px) 80%;
            margin-top: 30px;
            background-position: 53% 30px;
        }

        &__countries-block {
            margin-top: 30px;
        }

        &__countries-list {
            grid-gap: 0 1.1%;
            grid-template-columns: 8% 8% 10% 8% 8% 8% 8% 8% 8% 10%;
        }

        &__country-item {
            &:nth-child(n+11) {
                margin-top: 3.6%;
            }
        }

        &__country-desc {
            white-space: normal;
            word-break: break-word;
        }
    }
}

@media all and(max-width: @1023) {
    .map-block {
        &__selector-wrap {
            height: 100%;
            background-size: calc(100% + 74px) 70%;
            background-position: center;
        }

        &__countries-list {
            grid-gap: 0 1.2%;
            grid-template-columns: 10% 9% 9% 10% 10% 9% 9% 9% 9%;
        }

        &__country-item {
            &:nth-child(n+10) {
                margin-top: 4px;
            }
        }

        &__btn {
            position: static;
            margin: 30px auto 0;
            transform: none;
        }
    }
}

@media all and(max-width: @850) {
    .map-block {
        &__selector-wrap {
            background-size: calc(100% + 74px) 50%;
            background-position-y: 30%;
        }

        &__selector-list {
            max-width: 440px;
        }

        &__selector-title {
            font-size: @fz-4;
            padding-bottom: 7px;
        }

        &__countries-list {
            grid-gap: 0 1%;
            grid-template-columns: 13.5% 13.5% 13.5% 13.5% 13.5% 13.5% 13.5%;
        }

        &__country-item {
            &:nth-child(n+8) {
                margin-top: 4px;
            }
        }
    }
}

@media all and(max-width: @767) {
    .map-block {
        &__selector-wrap {
            background-size: calc(100% + 74px) 30%;
        }

        &__countries-list {
            grid-gap: 0 1%;
            grid-template-columns: 15% 15% 15% 15% 15% 15%;
        }

        &__country-item {
            &:nth-child(n+7) {
                margin-top: 4px;
            }
        }
    }
}

@media all and(max-width: 600px) {
    .map-block {
        &__selector-wrap {
            height: 100%;
            background-size: contain;
            background-position-y: 100px;
            background-position-x: center;
        }

        &__countries-list {
            grid-gap: 0 1%;
            grid-template-columns: 24% 24% 24% 24%;
        }

        &__country-item {
            align-items: center;
            text-align: center;

            &:nth-child(n+5) {
                margin-top: 5px;
            }
        }
    }
}

@media all and(max-width: @550) {
    .map-block {
        &__wrap {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        &__selector-list {
            max-width: 100%;
            flex-direction: column;
        }

        &__selector-item {
            &+& {
                margin-left: 0;
                margin-top: 15px;
            }
        }

        &__countries-list {
            grid-gap: 0 1%;
            grid-template-columns: 32% 32% 32%;
        }


        &__country-item {
            &:nth-child(n+4) {
                margin-top: 5px;
            }
        }
    }
}