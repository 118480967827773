.partners-block {
    &__wrap {
        padding-top: 45px;
        padding-bottom: 90px;
    }

    &__title {
        text-align: center;
    }

    &__slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 47px;
    }

    &__slide {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22%;

        &+& {
            margin-left: 4%;
        }

        &:nth-child(4n+5) {
            margin-left: 0;
        }

        &:nth-child(n+5) {
            margin-top: 4%;
        }
    }

    &__slide-img {
        position: relative;
        margin: 0 auto;
    }
}

@media all and(max-width: @1110) {
    .partners-block {
        &__wrap {
            padding-top: 40px;
            padding-bottom: 60px;
        }

        &__slider {
            margin-top: 30px;
        }
    }
}

@media all and(max-width: 780px) {
    .partners-block {
        &__slider {
            display: block;
            
            .slick-track{
                display: flex;
                align-items: center;
            }

            &.slick-initialized {
                .partners-block__slide+.partners-block__slide {
                    display: block;
                }
            }
        }

        &__slide {
            cursor: pointer;
            width: 100%;

            &+& {
                display: none;
                margin-left: 0;
            }

            &:nth-child(n+5) {
                margin-top: 0;
            }
        }

        &__slide-img{
            display: flex;
            align-items: center;
            justify-content: center;
            object-position: center;
        }

        &__wrap {
            padding-top: 40px;
            padding-bottom: 60px;
        }

        &__slider {
            margin-top: 30px;
        }
    }
}

@media all and(max-width: @550) {
    .partners-block {
        &__wrap {
            padding-top: 30px;
        }
    }
}