.check-block {

    &__top {
        padding: 40px 0 46px;
        background-image: url(../img/check-bg.png);
        background-repeat: no-repeat;
        background-size: cover;

        +.check-block__wrap {
            margin-top: 57px;
            padding-bottom: 70px;
        }
    }

    &__wrap {}

    &__title {
        text-align: center;
    }

    &__body {
        display: flex;
        align-items: center;
        margin-top: 35px;
    }

    &__desc {
        font-size: @fz-3;
        font-weight: @fw-s-bold;
        color: #fff;
        line-height: 1.2;

        .link {
            text-decoration: underline;
            font-weight: @fw-s-bold;
        }
    }

    &__link {
        margin-bottom: 10px;
        margin-left: 20px;
        width: 100%;
        max-width: 301px;
    }

    &__img {
        display: block;
        height: 49px;
        max-width: 301px;
        width: 100%;
    }

    &__subtitle {
        font-weight: @fw-s-bold;
    }

    &__find-form {
        margin-top: 12px;
    }

    &__form-title {
        font-size: @fz-7;
    }

    &__form-col-1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 9px;
    }

    &__input-wrap {
        width: 23.597%;

        &+& {
            margin-left: 1.87%;
        }

        &:nth-child(3) {
            margin-left: 2.4%;
        }

        &--big {
            width: 32.106%;

            &+& {
                margin-left: 1.8%;
            }
        }

        &--input {
            ::-webkit-calendar-picker-indicator {
                color: transparent;
                opacity: 1;
                background-image: url(../img/calendar.png);
                background-size: 14px 14px;
                margin-right: -8px;
                margin-top: 1px;
                cursor: pointer;
            }
        }
    }

    &__input-label {
        font-size: 12px;

        &--checkbox {
            display: flex;
            padding-bottom: 2px;
            padding-top: 12px;
            cursor: pointer;
            transition: .3s;

            &:hover,
            &:focus {
                color: @gray-blue;
            }
        }
    }

    &__select-wrap {
        position: relative;

        &:before {
            border-bottom: 2px solid #222222;
            border-right: 2px solid #222222;
            content: '';
            display: block;
            height: 8px;
            margin-top: -4px;
            pointer-events: none;
            position: absolute;
            z-index: 2;
            right: 15px;
            top: 50%;
            transform-origin: 66% 66%;
            transform: rotate(45deg);
            transition: .15s ease-in-out;
            width: 8px
        }
    }

    &__select {
        position: relative;
        margin-top: 3px;

        &:before {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            background-color: #fff;
        }
    }

    &__checkbox {
        margin-right: 5px;
        cursor: pointer;
    }

    &__form-col-2 {
        display: flex;
        margin-top: 9px;
    }

    &__input {
        margin-top: 3px;
    }

    &__selects-wrap {
        display: flex;

        .check-block__select {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .check-block__select-wrap {
            width: 100%;
        }

        .check-block__select-wrap+.check-block__select-wrap .check-block__select {
            border-left: none;
            border-radius: 2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-right: 1px solid #ccc;
        }
    }

    &__form-btn {
        height: 46px;
        max-width: 106px;
        margin-left: auto;
        margin-top: -3px;
    }
}

@media all and(max-width: @1110) {
    .check-block {
        &__top {
            padding: 40px 0 40px;

            +.check-block__wrap {
                margin-top: 30px;
                padding-bottom: 40px;
            }
        }

        &__body {
            margin-top: 30px;
        }
    }
}

@media all and(max-width: @850) {
    .check-block {
        &__top {
            height: 100%;
            background-position-x: 80%;
        }

        &__body {
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
        }

        &__link {
            margin-left: 0;
            margin-top: 20px;
        }

        &__desc {
            font-size: @fz-4;
            text-align: center;
        }
    }
}

@media all and(max-width: @767) {
    .check-block {
        &__form-col-1 {
            flex-wrap: wrap;
        }

        &__form-col-2 {
            flex-wrap: wrap;
        }

        &__input-wrap {
            width: 49%;

            &+& {
                margin-left: 2%;
            }

            &:nth-child(n+3) {
                margin-top: 1.8%;
            }

            &:nth-child(2n+3) {
                margin-left: 0;
            }

            &--big {
                width: 49%;

                &+& {
                    margin-left: 1.8%;
                }

                &:nth-child(2n+3) {
                    margin-left: 0;
                }

                &:nth-child(n+3) {
                    margin-top: 1.8%;
                }
            }
        }
    }
}

@media all and(max-width: @550) {
    .check-block {
        &__top {
            padding: 30px 0 30px;

            +.check-block__wrap {
                padding-bottom: 30px;
            }
        }

        &__body{
            margin-top: 30px;
        }

        &__desc {
            text-align: justify;
            line-height: 1.3;
        }

        &__input-wrap {
            &--big {
                width: 100%;

                &+& {
                    margin-left: 0;
                    margin-top: 1.8%;
                }
            }
        }

        &__input-label {
            &--checkbox {
                padding-top: 12px;
                padding-bottom: 2;
            }
        }

        &__checkbox {
            margin-top: -2px;
        }

        &__form-btn {
            margin-top: 20px;
            max-width: 100%;
        }
    }
}

@media all and(max-width: 370px) {
    .check-block {
        &__input-wrap {
            width: 100%;

            &+& {
                margin-left: 0;
                margin-top: 1.8%;
            }

            &:nth-child(3) {
                margin-top: 4.5%;
            }

            &--big {
                &:nth-child(3) {
                    margin-top: 1.8%;
                }
            }
        }
    }
}