.menu{
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 320px;
    min-height: 100vh;
    max-height: 100%;
    overflow-y: auto;
    background-color: @green;
    transform: translateX(-100%);
    transition: .3s;
    
    &.is-active{
        transform: translateX(0);
    }

    &__close-btn {
        position: absolute;
        top: 29px;
        right: 29px;
        cursor: pointer;
        width: 32px; 
        height: 32px;

        &:hover,
        &:focus {
            .menu__close-btn-line {
                background-color: @green;
            }
        }
    }

    &__close-btn-line {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        width: 32px;
        height: 3px;
        transition: .3s;

        &:first-child {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 48px 30px;
        min-height: 100vh;
    }

    .footer__nav-list{
        margin-top: 100px;
        width: 100%;
        text-align: center;
    }

    .footer__nav-item{
        font-size: 21px;

        a{
            display: block;
            line-height: 2;
        }
    }

    .footer__contacts-list{
        margin-top: 40px;
    }

    .footer__socials-list{
        margin-top: 30px;
        width: 100%;
    }
}

@media all and(max-width: 700px){
    .menu {
        &__wrap{
            padding: 32px 30px;
        }

        &__close-btn {
            top: 26px;
            right: 19px;
        }

        .header__search-form{
            width: 88%;
            top: 18px;
            left: 20px;
        }
    }
}