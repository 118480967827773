.btn {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: @yellow;
    font-weight: @fw-s-bold;
    font-size: @fz-4;
    text-transform: uppercase;
    transition: .3s;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    width: 100%;
    height: 71px;
    border-radius: 40px;

    &:hover,
    &:focus {
        outline: none;
        background-color: @yellow-hov;
    }

    &--gr {
        background-color: @green;
        font-size: 19px;
        border-radius: 3px;
        color: #fff;
        text-transform: none;

        &:hover,
        &:focus {
            background-color: @green-hov;
        }
    }
}

@media all and(max-width:@850) {
    .btn {
        height: 50px;
    }
}

@media all and(max-width:550px) {
    .btn {
        height: 58px;

        &__wrap {
            &:after {
                display: none;
            }
        }
    }
}