.getting-block {
    background-color: @gray-lighter;

    &__wrap {
        padding-top: 50px;
        padding-bottom: 58px;
    }

    &__title {}

    &__list {
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
    }

    &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 31.93%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        padding: 20px 0;

        &+& {
            margin-left: 2.1%;
        }
    }

    &__desc {
        font-weight: @fw-medium;
        font-size: @fz-3;
        color: #fff;
        text-align: center;
        line-height: 1.1;
    }

    &__img-wrap{
        padding-bottom: 77%;
        width: calc(100% - 20px);
        margin-top: 20px;
    }

    &__big-text {
        color: #fff;
        font-weight: @fw-norm;
        text-transform: none;
        text-align: center;
        margin-top: 10px;
    }

    &__link {
        &--m {
            font-size: @fz-3;
            text-decoration: underline;
            margin-top: 10px;
        }
    }

    &__subtitle {
        text-align: center;
        margin-top: 55px;
    }

    &__limits-list {
        display: flex;
        margin-top: 30px;
    }

    &__limit-item {
        width: 48.773%;
        background-color: @gray-light;
        padding: 33px 38px 41px;
        border-radius: 4px;
        height: auto;

        &+& {
            margin-left: 2.454%;
        }

        .getting-block__desc {
            line-height: 1.2;
            color: #000;
            font-weight: @fw-medium;
            text-align: left;
        }
    }

    &__date {
        margin-top: 20px;
        margin-top: 6px;
        font-size: @fz-3;
        font-weight: @fw-s-bold;
        color: #fff;

        span {
            font-size: 41px;
            font-weight: @fw-s-bold;
            margin-right: 5px;
        }
    }
}

@media all and(max-width: @1110) {
    .getting-block {
        &__wrap {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__list {
            margin-top: 30px;
        }

        &__subtitle {
            margin-top: 30px;
        }
    }
}

@media all and(max-width: @1023) {
    .getting-block {
        &__big-text {
            font-size: 24px;
        }

        &__desc {
            font-size: @fz-3-1;
        }

        &__item {
            padding: 20px 10px;
            min-height: 180px;
        }

        &__limit-item {
            padding: 20px;
        }

        &__date {
            span {
                font-size: @fz-2;
            }
        }
    }
}

@media all and(max-width: @767) {
    .getting-block {
        &__list {
            flex-direction: column;
            max-width: 600px;
            margin: 30px auto 0;
        }

        &__item {
            width: 100%;

            &+& {
                margin-left: 0;
                margin-top: 20px;
            }
        }

        &__limits-list {
            flex-direction: column;
            max-width: 600px;
            margin: 30px auto 0;
        }

        &__limit-item {
            width: 100%;

            &+& {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}

@media all and(max-width: @550) {
    .getting-block {
        &__wrap {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        &__desc {
            font-size: @fz-4;
        }

        &__date {
            font-size: @fz-4;

            span {
                font-size: @fz-3;
            }
        }
    }
}