.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  &--title{}

  &__wrap {
    position: relative;
    overflow: hidden;
  }
}