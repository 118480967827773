@media all and (max-width:@screen-width-s) {
    .slick-dots {
    top: -18px;
    right: 0;
    padding-right: 10px;

        li {
            margin: 0;
            height: 10px;
            width: 10px; 

            button {
                width: 5px;
                height: 5px;
                &:before {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    left: 5px;
                    top: 5px;
                }
            }
        }
    }

    .js_members_slider > .slick-dots { 
        justify-content: flex-start;
        padding-left: 0;
        top: -5px; 
    }

    .js_sticky_slider {

      .slick-dots {

        li {
            margin: 0px 2px;

          button {
              width: 8px;
              height: 8px;

            &:before {
                width: 8px;
                height: 8px;
            }
          }
        }
      }
    }
}