.title-block {
    background-color: @gray-lighter;
    border-bottom: 1px solid #E1E1E1;

    &__slider {
        &.slick-initialized {
            .title-block__slide-wrap+.title-block__slide-wrap {
                display: block;
            }
        }
    }

    &__slide-wrap {
        &+& {
            display: none;
        }
    }

    &__slide {
        cursor: pointer;
        padding-bottom: 28.067%;
        height: 421px;
    }

    &__slide-img {
        &--mob {
            &.img {
                display: none;
            }
        }
    }

    &__slide-content {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        top: 60px;
    }

    &__slide-title {
        font-size: 45px;
        color: #fff;
        font-weight: @fw-norm;
        line-height: 1.2;
        max-width: 735px;
        text-shadow: 1px 1px 0px rgba(77, 77, 77, 1);
    }

    &__slide-desc {
        font-size: @fz-3;
        color: @gray;
        font-weight: @fw-medium;
        margin-top: 13px;
    }

    &__slide-subtitle {
        color: #fff;
        text-transform: none;
        margin-top: 22px;
        text-shadow: 1px 1px 0px rgba(77, 77, 77, 1);
    }

    &__buttons-wrap {
        padding-top: 21px;
        padding-bottom: 18px;
    }

    &__buttons-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__button-item {
        width: 48.86%;

        &+& {
            margin-left: 2%;
        }
    }

    &__btn {}
}

@media all and(max-width: 1650px) {
    .title-block {
        &__slide {
            height: 400px;
        }

        &__slide-title{
            font-size: 38px;
        }
    }
}

@media all and(max-width: @1110) {
    .title-block {
        &__slide {
            height: 350px;
        }

        &__slide-content {
            top: 30px;
        }

        &__slide-title {
            font-size: 35px;
        }
    }
}

@media all and(max-width: @850) {
    .title-block {
        &__slide {
            height: 330px;
        }

        &__slide-title {
            font-size: @fz-2;
        }
    }
}

@media all and(max-width: @550) {
    .title-block {
        &__slide {
            min-height: initial;
            height: initial;
            padding-bottom: initial;

            &:before {
                position: absolute;
                z-index: 1;
                content: '';
                bottom: 0;
                left: 70%;
                transform: translateX(-50%);
                width: 250px;
                height: 215px;
                background-image: url(../img/globe.png);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        &__slide-img {
            &.img {
                display: none;
            }

            &--mob {
                &.img {
                    display: initial;
                }
            }
        }

        &__slide-content {
            position: relative;
            z-index: 2;
            padding: 50px 20px 200px;
            top: 0;
        }

        &__slide-title {
            font-size: 24px;
        }

        &__slide-desc {
            font-size: @fz-4;
            margin-top: 10px;
            line-height: 1.2;
        }

        &__slide-subtitle {
            margin-top: 10px;
        }

        &__buttons-list {
            flex-direction: column;
        }

        &__button-item {
            width: 100%;

            &+& {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}