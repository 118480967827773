.header {
    &__wrap {}

    &__top {
        display: flex;
        padding-top: 15px;
        padding-bottom: 7px;
    }

    &__logo-wrap {
        position: relative;
        display: flex;
    }

    &__logo-link {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__logo-img {
        width: 107px;
        height: 108px;
        object-fit: cover;
    }

    &__logo-year {
        color: @gray;
        font-size: @fz-5;
        width: 100%;
        margin-top: 7px;
        display: block;
        line-height: 1;
        text-align: center;
    }

    &__logo-desc {
        position: relative;
        font-size: @fz-2;
        font-weight: @fw-medium;
        max-width: 323px;
        width: 100%;
        margin-left: 27px;
        margin-top: 24px;
        line-height: 1;
        padding-right: 33px;

        &:before {
            position: absolute;
            content: '';
            right: 0;
            top: 6px;
            width: 3px;
            height: 56px;
            background-color: #000;
        }
    }

    &__desc {
        max-width: 440px;
        color: @gray;
        font-size: @fz-3;
        margin-top: 15px;
        line-height: 1.25;
        padding-left: 25px;

        &--mob {
            display: none;
        }
    }

    &__partner-wrap {
        position: relative;
        margin-top: 17px;
        margin-left: auto;
        padding-left: 20px;
    }

    &__partner-link {}

    &__partner-img {}

    &__partner-desc {
        font-size: @fz-5;
        max-width: 142px;
        margin-top: 1px;
        text-align: center;
    }

    &__menu-btn {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
        height: 25px;
        width: 25px;
        margin-top: 0;

        &:hover,
        &:focus {
            outline: none;

            .header__menu-btn-line {
                // background-color: @c-blue-l;
            }
        }
    }

    &__menu-btn-line {
        width: 25px;
        height: 2px;
        background-color: #000;
        transition: .3s;

        &+& {
            margin-top: 7px;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media all and(max-width: @1200) {
    .header {
        &__logo-link {
            justify-content: center;
        }

        &__logo-desc {
            padding-right: 25px;
            font-size: 24px;
            max-width: 270px;
            margin-top: 36px;
            margin-left: 20px;

            &:before {
                top: 0px;
            }
        }

        &__logo-img {
            width: 80px;
            height: 80px;
        }

        &__desc {
            margin-top: 30px;
            font-size: @fz-3-1;
            max-width: 400px;
        }
    }
}

@media all and(max-width: @1023) {
    .header {
        &__logo-wrap {
            flex-shrink: 0;
        }

        &__logo-desc {
            padding-right: 20px;
            font-size: @fz-3;
            max-width: 225px;
        }

        &__desc {
            padding-left: 15px;
        }
    }
}

@media all and(max-width: @920) {
    .header {
        &__top {
            align-items: center;
        }

        &__logo-img {
            width: 60px;
            height: 60px;
        }

        &__logo-wrap {
            align-items: center;
        }

        &__logo-link {
            justify-content: flex-start;
        }

        &__logo-desc {
            margin-top: 0;

            &::before {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__desc {
            display: none;

            &--mob {
                display: block;
                font-size: 16px;
                padding-left: 10px;
                margin-top: 0;
            }
        }

        &__partner-wrap {
            text-align: center;
            margin-top: 0;
            margin-right: 30px;
        }

        &__partner-img {
            width: 100px;
        }

        &__partner-desc {
            font-size: 10px;
        }

        &__menu-btn {
            display: flex;
        }
    }
}

@media all and(max-width: @550) {
    .header {
        &__top {
            padding-top: 7px;
        }

        &__logo-wrap {
            padding-top: 0;
        }

        &__logo-year {
            font-size: 10px;
            margin-top: 3px;
        }

        &__logo-desc {
            font-size: 12px;
            margin-left: 10px;
            padding-right: 10px;
            max-width: 100px;

            &:before {
                width: 2px;
                height: 45px;
            }
        }

        &__desc {
            &--mob {
                font-size: 12px;
            }
        }

        &__partner-wrap {
            padding-left: 0;
            margin-right: 15px;
            margin-left: 15px;
            margin-left: auto;
        }

        &__partner-img {
            width: 75px;
        }

        &__partner-desc {
            display: none;
        }

        &__menu-btn {
            margin-left: auto;
        }
    }
}

@media all and(max-width: 410px) {
    .header {
        &__top{
            padding-left: 15px;
            padding-right: 15px;
        }

        &__logo-desc {
            max-width: 85px;
            padding-right: 6px;
        }

        &__desc {
            max-width: 125px;
            word-break: break-all;
            padding-right: 5px;
        }

        &__partner-wrap {
            display: none;
        }
    }
}