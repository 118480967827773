.footer {
    background-color: @dark-gray;

    &__nav-wrap {
        padding-top: 28px;
    }

    &__nav-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -4px;
    }

    &__nav-item {
        &+& {
            margin-left: 30px;
        }
    }

    &__nav-link {
        font-size: @fz-5;
        color: #fff;
        font-weight: @fw-s-bold;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        padding-bottom: 35px;
    }

    &__col-1 {
        max-width: 521px;
        width: 100%;
    }

    &__logo-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 520px;
    }

    &__logo {}

    &__desc {
        color: @light-text;
        font-size: @fz-5;
        max-width: 370px;
        line-height: 1.2;
        margin-left: 20px;
        margin-top: 5px;
    }

    &__socials-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 54px;
    }

    &__socials-title {
        display: flex;
        flex-direction: column;
        color: @light-text;
        font-size: @fz-5;
    }

    &__white-text {
        color: #fff;
    }

    &__socials-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 242px;
        width: 100%;
        margin-left: 20px;

        &--round {
            margin-left: 0;
            max-width: 178px;
            margin-top: 8px;
        }
    }

    &__social-item {
        &+& {
            margin-left: 20px;
        }
    }

    &__social-link {
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &--inst {
            background-image: url(../img/inst.png);
            width: 32px;
            height: 32px
        }

        &--fb {
            background-image: url(../img/fb.png);
            width: 32px;
            height: 32px;
        }

        &--youtube {
            background-image: url(../img/youtube.png);
            width: 32px;
            height: 32px;
        }

        &--tg {
            background-image: url(../img/tg.png);
            width: 32px;
            height: 32px;
        }

        &--viber {
            background-image: url(../img/viber.png);
            width: 30px;
            height: 32px;
        }

        &--wpp {
            background-image: url(../img/wpp.png);
            width: 32px;
            height: 32px;
        }
    }

    &__col-2 {
        margin-left: 40px;
        max-width: 516px;
        width: 100%;
        margin-top: 5px;
    }

    &__contacts-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

    }

    &__contacts-col-1 {
        max-width: 270px;
        width: 100%;
    }

    &__subtitle {
        position: relative;
        color: @light-text;
        font-size: @fz-5;
        line-height: 1.1;

        &:before {
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &--tel {
            &:before {
                content: '';
                top: 0;
                left: -41px;
                width: 19px;
                height: 19px;
                background-image: url(../img/phone.png);
            }
        }

        &--graph {
            &:before {
                content: '';
                top: 0;
                left: -41px;
                width: 20px;
                height: 20px;
                background-image: url(../img/tima.png);
            }
        }
    }

    &__links {}

    &__link {
        display: block;
        font-size: @fz-5;
        font-weight: @fw-s-bold;

        &+&{
            margin-top: 5px;
        }
    }

    &__contacts-col-2 {
        margin-left: 30px;
    }

    &__time {
        font-size: @fz-5;
        margin-top: 3px;
        color: #fff;

        span {
            font-size: @fz-3;
            margin-left: 3px;
        }
    }

    &__payment-block {
        display: flex;
        align-items: center;
        margin-top: 82px;
    }

    &__payment-list {
        display: flex;
        max-width: 158px;
        width: 100%;
        justify-content: space-between;
        margin-left: 26px;
    }

    &__payment-item {}

    &__payment-img {}

    &__bottom {
        border-top: 1px solid #636363;
    }

    &__copyright {
        margin-left: auto;
        max-width: 320px;
        width: 100%;
        margin-right: 377px;
        padding-top: 24px;
        padding-bottom: 26px;
        letter-spacing: -0.3px;

        span {
            letter-spacing: initial;
        }
    }
}

@media all and(max-width: @1110) {
    .footer {
        &__wrap {
            margin-top: 30px;
            padding-bottom: 30px;
        }

        &__nav-list {
            flex-wrap: wrap;
        }

        &__nav-item {
            text-align: center;

            &+& {
                margin-left: 20px;
            }
        }

        &__links {
            display: flex;
            flex-direction: column;
        }

        &__contacts-col-1 {
            max-width: 170px;
        }

        &__subtitle {
            &--tel {
                &:before {
                    left: -25px;
                    width: 15px;
                    height: 15px;
                }
            }

            &--graph {
                &:before {
                    left: -25px;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        &__socials-list {
            max-width: 200px;

            &--round {
                max-width: 150px;
            }
        }

        &__col-2 {
            max-width: 390px;
        }
    }
}

@media all and(max-width: @1023) {
    .footer {
        &__nav-list {
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            max-width: 600px;
            margin: 0 auto;
        }

        &__nav-item {
            width: 25%;

            &+& {
                margin-left: 2%;
            }

            &:nth-child(4n+5) {
                margin-left: 0;
            }

            &:nth-child(n+5) {
                margin-top: 2%;
            }
        }
    }
}

@media all and(max-width: @850) {
    .footer {
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
        }

        &__col-1 {
            max-width: 100%;
        }

        &__logo-wrap {
            max-width: 100%;
            align-items: center;
        }

        &__socials-block {
            margin-top: 30px;
            flex-direction: column;
            align-items: flex-start;
        }

        &__socials-list {
            margin-top: 20px;
            margin-left: 0;

            &--round {
                max-width: 150px;
            }
        }

        &__desc {
            max-width: 100%;
        }

        &__col-2 {
            max-width: 100%;
            margin-left: 0;
            margin-top: 40px;
            max-width: 500px;
        }

        &__contacts-block {
            flex-direction: column;
            align-items: flex-start;
        }

        &__contacts-col-1 {
            margin-left: 25px;
            max-width: 300px;
        }

        &__contacts-col-2 {
            margin-top: 20px;
            margin-left: 25px;
        }

        &__links {
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 10px;
        }

        &__link {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            &+&{
                margin-top: 0;
            }
        }

        &__payment-block {
            margin-top: 20px;
        }

        &__copyright {
            margin: 0 auto;
            padding: 20px;
            max-width: 100%;
            text-align: center;
        }
    }
}

@media all and(max-width: @767) {
    .footer {
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
        }

        &__socials-list {
            margin-left: 0;
            margin-left: 0;
            margin-top: 20px;
        }

        &__develop-desc {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}

@media all and(max-width: @550) {
    .footer {
        &__nav-wrap {
            height: auto;
        }

        &__nav-list {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &__nav-item {
            width: 100%;
            text-align: left;

            &+& {
                margin-left: 0;
                margin-top: 15px;
            }
        }

        &__logo-wrap {
            flex-direction: column;
            align-items: flex-start;
        }

        &__desc {
            margin-left: 0;
            margin-top: 20px;
        }

        &__contacts-col-1 {
            max-width: 170px;
        }
    }
}