.title {
    font-weight: @fw-norm;
    line-height: @lh-small;

    &--w{
        color: #fff;

        &.title--under{
            &::before{
                background-color: #fff;
            }
        }
    }

    &--under{
        position: relative;
        padding-bottom: 25px;
        text-align: center;

        &:before{
            position: absolute;
            content: '';
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 80px;
            height: 2px;
            background-color: #000;
        }
    }

    &--l1 {
        font-weight: @fw-s-bold;
        font-size: @fz-2;
        text-transform: uppercase;
    }

    &--l2 {
        font-weight: @fw-s-bold;
        font-size: @fz-3;
    }

    &--l3 {
        font-size: @fz-3;
    }

    &--l4 {
        font-weight: @fw-s-bold;
        font-size: @fz-4;
    }

    &--th{
        font-weight: @fw-norm;
    }
}

@media all and(max-width:1000px) {
    .title {
        &--l1 {
            font-size: 28px;
        }
    }
}

@media all and(max-width:767px) {
    .title {
        &--l1 {
            font-size: 25px;
        }
    }
}

@media all and(max-width:@550) {
    .title {
        &--l1 {
            font-size: 22px;
        }
    }
}