.table {
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: 32px;

    +.card-page__subtitle{
        margin-top: 38px;
    }

    &__wrap {
        width: 100%;
        overflow-x: auto;
    }

    &__header {}

    &__header-field {
        text-align: left;
    }

    &__body {}

    &__row {
        border-bottom: 1px solid #eee;

        &--header {
            &:hover {
                background-color: #fff;
            }

            &:last-child {
                border-bottom: 1px solid #eee;
            }
        }

        &--bold {
            font-weight: @fw-bold;
        }
    }

    &__field {
        position: relative;
        text-align: left;
        padding: 6px 8px 19px;
        vertical-align: top;
        letter-spacing: -0.6px;
        line-height: 1.2;

        &:first-child {
            padding-left: 0;
        }

        &--header {
            padding: 8px 8px 21px;
            letter-spacing: -0.8px;
            line-height: 1.1;
        }

        &--130px {
            min-width: 130px;
        }

        &--150px {
            min-width: 150px;
        }

        &--160px {
            min-width: 160px;
        }

        &--170px {
            min-width: 170px;
        }

        &--180px {
            min-width: 180px;
        }

        &--185px {
            min-width: 183px;
        }

        &--195px {
            min-width: 195px;
        }

        &--200px {
            min-width: 200px;
        }

        &--220px {
            min-width: 220px;
        }

        &--247px {
            max-width: 247px;
        }

        &--350px {
            min-width: 350px;
        }
    }
}