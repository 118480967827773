.text-title {
    margin-top: 64px;

    &__wrap {
        padding-bottom: 76px;
    }

    &__title {}

    &__body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 70px;

        +.text-title__subtitle {
            margin-top: -5px;
        }
    }

    &__col-1 {
        width: 73.685%;
    }

    &__subtitle {
        font-weight: @fw-s-bold;
    }

    &__desc {
        font-size: @fz-3;
        margin-top: 25px;
        letter-spacing: -0.3px;
        line-height: 1.2;
        width: 100%;
    }

    &__col-2 {
        width: 24.123%;
        padding-bottom: 24.475%;
        margin-left: 2.19%;
        margin-top: 5px;
    }

    &__img {}
}

@media all and(max-width: @1110) {
    .text-title {
        margin-top: 40px;

        &__wrap {
            padding-bottom: 40px;
        }

        &__body {
            margin-top: 30px;

            +.text-title__subtitle {
                margin-top: 30px;
            }
        }
    }
}

@media all and(max-width: @1023) {
    .text-title {
        &__subtitle {
            font-size: 18px;
        }

        &__desc {
            font-size: 18px;
            margin-top: 10px;
        }
    }
}

@media all and(max-width: @767) {
    .text-title {
        &__body {
            flex-direction: column;
            align-items: center;
        }

        &__col-1 {
            width: 100%;
        }

        &__col-2 {
            width: 60%;
            margin: 20px auto 0;
            padding-bottom: 61%;
        }
    }
}

@media all and(max-width: @550) {
    .text-title {
        margin-top: 30px;

        &__wrap {
            padding-bottom: 30px;
        }

        &__col-2 {
            width: 90%;
            padding-bottom: 92%;
        }
    }
}

.dg-widget-link{
    ~ iframe{
        width: 100%;
        max-width: 500px;
    }
}