.nav {
    position: relative;
    width: 100%;
    background-color: @gray;
    height: 55px;
    padding: 0 30px;

    &__close-btn {
        display: none;
        position: absolute;
        top: 19px;
        right: 19px;
        cursor: pointer;
        width: 32px;
        height: 32px;

        &:hover,
        &:focus {
            .menu__close-btn-line {}
        }
    }

    &__close-btn-line {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        width: 32px;
        height: 4px;
        transition: .3s;

        &:first-child {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }

    &__item {
        position: relative;
        height: 100%;

        &+& {
            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 20px;
                background-color: @yellow;
                transition: .3s;
            }
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &:hover,
        &:focus,
        &.is-active {
            +.nav__item {
                &:before {
                    opacity: 0;
                }
            }

            .nav__link {
                background-color: @yellow;
                color: #000;
            }
        }

        &--dropdown {
            transition: .3s;

            &:hover,
            &:focus {
                background-color: @yellow;

                .nav__dropdown-list {
                    opacity: 1;
                    z-index: 10;
                }

                .nav__link {
                    color: #000;
                }

                +& {
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        font-size: @fz-5;
        font-weight: @fw-medium;
        color: #fff;
        transition: .3s;
        padding: 0 20px;
    }

    &__dropdown-list {
        position: absolute;
        z-index: -1;
        top: 55px;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: .3s;
        background-color: @gray;
        padding: 2px 20px 5px 24px;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    &__dropdown-item {
        padding: 17px 0 16px;
        line-height: 1.1;

        &+& {
            border-top: 1px solid #fff;
        }
    }

    &__dropdown-link {
        position: relative;
        font-size: @fz-5;
    }
}

@media all and(max-width: @1200) {
    .nav {
        &__link {
            padding: 0 10px;
            text-align: center;
        }
    }
}

@media all and(max-width:@920) {
    .nav {
        transform: translateX(100%);
        position: fixed;
        z-index: 11;
        top: 0;
        right: 0;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0;
        max-width: 300px;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        padding: 22px 30px 70px;
        background-position: center -30px;
        margin-left: 0;
        transition: .3s;
        overflow: auto;
        border-radius: 0;

        &.is-active {
            transform: translateY(0);
        }

        &__close-btn {
            display: block;
        }

        &__list {
            flex-direction: column;
            margin-top: 37px;
            align-items: flex-start;
            height: initial;
            padding: 0;
        }

        &__item {
            padding: 0;
            width: 100%;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 10px;
                transform: none;
                width: 1px;
                height: 20px;
                background-color: @yellow;
                transition: .3s;
            }

            &+& {

                &::before {
                    transform: none;
                    top: 10px;
                }
            }

            &:hover,
            &:focus,
            &.is-active {
                +.nav__item {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &__dropdown-list {
            top: 43px;
            border: 1px solid #fff;
            background-color: #fff;
            padding-left: 10px;
            padding-right: 10px;
        }

        &__dropdown-item {
            height: 50px;
            padding: 0;

            &+& {
                border-color: #000;
            }
        }

        &__dropdown-link {
            display: flex;
            align-items: center;
            height: 100%;
            color: #000;
        }

        &__link {
            line-height: 2.5;
            font-size: @fz-4;
            text-align: left;
        }
    }
}