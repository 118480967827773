.videos-block {
    background-color: @gray-lighter;

    &__wrap {
        padding-top: 65px;
        padding-bottom: 42px;
    }

    &__title {}

    &__list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 49px;
    }

    &__item {
        width: 31.843%;

        &+& {
            margin-left: 2.235%;
        }

        &:nth-child(3n+4) {
            margin-left: 0;
        }

        &:nth-child(n+4) {
            margin-top: 2.235%;
        }
    }

    &__img-wrap {
        position: relative;
        cursor: pointer;
        padding-bottom: 56.75%;

        &:before {
            position: absolute;
            z-index: 3;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url(../img/video.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 62px;
            height: 62px;
            transition: .3s;
        }

        &.play {
            &::before {
                display: none;
            }

            .videos-block__video-img {
                display: none;
            }
        }

        &:hover,
        &:focus {
            &::before {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }

    &__video-img {
        z-index: 2;
    }

    &__video {
        border: none;
    }

    &__desc {
        font-size: @fz-3;
        font-weight: @fw-s-bold;
        margin-top: 15px;
        letter-spacing: -0.4px;
        max-width: 242px;
        line-height: 1.2;
    }
}

@media all and(max-width: @1110) {
    .videos-block {
        &__wrap {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__list {
            margin-top: 30px;
        }
    }
}

@media all and(max-width: @850) {
    .videos-block {
        &__desc {
            font-size: @fz-4;
            margin-top: 10px;
        }

        &__img-wrap {
            padding-bottom: 71.75%;
        }
    }
}


@media all and(max-width: @767) {
    .videos-block {
        &__list {
            flex-direction: column;
            max-width: 400px;
            margin: 30px auto 0;
        }

        &__item {
            width: 100%;

            &+& {
                margin-left: 0;
                margin-top: 20px;
            }
        }

        &__img-wrap {
            padding-bottom: 56.75%;
        }
    }
}

@media all and(max-width: @550) {
    .videos-block {
        &__wrap {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}