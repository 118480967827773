.input {
    font-size: @fz-5;
    border: 1px solid #CCCCCC;
    transition: .3s;
    white-space: nowrap;
    outline: none;
    overflow: hidden;
    width: 100%;
    height: 37px;
    background-color: #fff;
    color: #000;
    padding: 13px 12px;
    border-radius: 2px;
    letter-spacing: -0.6px;
    -webkit-appearance: none;

    &--select {
        position: relative;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &::placeholder {
        color: #555555;
    }

    &:hover,
    &:focus {
        outline: none;
        border-color: @gray-blue;
    }
}


@media all and (max-width: @550) {
    .input {
        max-width: 100%;
    }
}