.link {
    position: relative;
    color: #000;
    transition: color 0.3s;
    line-height: @lh-small;
    cursor: pointer;

    &-abs {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &:hover,
    &:focus {
        outline: none;
    }

    &--w {
        position: relative;
        color: #fff;

        &:hover,
        &:focus {
            color: @yellow-hov;
        }
    }

    &--y {
        color: @yellow;

        &:hover,
        &:focus {
            color: @yellow-hov;
        }
    }

    &--gr {
        color: @green;

        &:hover,
        &:focus {
            color: @green-hov;
        }
    }

    &--g {
        color: @gray;

        &:hover,
        &:focus {
            color: @yellow-hov;
        }
    }

    &--b {
        color: #000;

        &:hover,
        &:focus {
            color: @green;
        }
    }

    &--phone {
        &:before {
            position: absolute;
            top: 2px;
            left: 0;
            content: '';
            background-image: url(../img/phone.svg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 12px;
            height: 12px;
        }

        padding-left: 33px;
    }

    &--wpp {
        &:before {
            position: absolute;
            top: -3px;
            left: 0;
            content: '';
            background-image: url(../img/wpp.svg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 22px;
            height: 22px;
        }

        padding-left: 40px;
    }

    &--address {
        &:before {
            position: absolute;
            top: 2px;
            left: -35px;
            content: '';
            background-image: url(../img/map.svg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 15px;
            height: 15px;
        }
    }

    &--email {
        &:before {
            position: absolute;
            left: 0;
            top: 3px;
            content: '';
            background-image: url(../img/mail.svg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 15px;
            height: 12px;
        }
    }
}

@media all and(max-width: @1110) {
    .link {
        &--address {
            &:before {
                left: -25px;
            }
        }

        &--phone {
            padding-left: 25px;
        }

        &--wpp {
            padding-left: 25px;

            &:before {
                width: 18px;
                height: 18px;
            }
        }
    }
}

@media all and(max-width: @1110) {
    .link {
        &--address {

            &:hover,
            &:focus {
                color: transparent;
            }
        }

        &--phone {

            &:hover,
            &:focus {
                color: transparent;
            }
        }

        &--wpp {

            &:hover,
            &:focus {
                color: transparent;
            }
        }
    }
}